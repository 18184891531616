<template>
  <div class="container">
    <div class="lang">
      <el-select v-model="value" placeholder="please choose" @change="clickChange">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>
    <h2>UniLive Software License and Service Agreement</h2>
    <p>
      Introduction<br />
      The UniLive Software License and Service Agreement (hereinafter referred
      to as "this Agreement") is PIX TECHNOLOGY PTE The agreement between the
      UniLive software (hereinafter referred to as "UniLive" or "us") under LTD
      company and you (hereinafter referred to as "user") regarding your
      download, installation, login, use of this software, and use of UniLive
      related services. Please carefully read the contents of this agreement. If
      you have any questions about the content or page prompts of this
      agreement, please do not proceed to the next step. You can consult through
      the official customer service of the UniLive platform so that we can
      explain and clarify for you. By clicking on the page or directly starting
      to use UniLive software related services or other means, you confirm that
      you have agreed to this agreement 
    </p>
    <p>
      If you are a minor under the age of 18 or do not have full capacity for
      civil conduct due to intellectual or mental health conditions, please read
      this agreement and other related agreements under the guidance and
      accompaniment of your guardian, pay special attention to the relevant
      terms for the use of minors, and obtain the consent of your guardian
      before using UniLive software and related services. Special attention
      should be paid to not using the recharge and consumption related services
      provided by UniLive without the explicit consent of the guardian, and the
      guardian should fulfill their guardianship responsibilities effectively.
    </p>
    <h3>This agreement will help you understand the following content</h3>
    <p>1、 Definition</p>
    <p>2、 Scope of Application and Revision of the Agreement</p>
    <p>3、 UniLive Software License and Usage Specification</p>
    <p>4、 Registration, use, and cancellation of accounts</p>
    <p>5、 Protection of User Personal Information</p>
    <p>6、 Terms of Use for Minors</p>
    <p>7、 User behavior norms</p>
    <p>8、 Data Usage Standards</p>
    <p>9、 Obligation to pay taxes in accordance with the law</p>
    <p>10、 Intellectual Property</p>
    <p>11、 Promotion</p>
    <p>12、 Special agreements regarding individual services</p>
    <p>13、 Change, interruption, and termination of services</p>
    <p>14、 Disclaimer</p>
    <p>15、 Default handling</p>
    <p>16、 Other</p>
    <h3>1、 Definition</h3>
    <p>
      1. The UniLive platform refers to the UniLive APP, as well as other
      websites, web pages, clients, mini programs, and new service carriers that
      may be opened in the future with the development of technology.
    </p>
    <p>
      2. This service/service refers to the related services provided to users
      through the UniLive platform (including but not limited to providing
      information storage space to provide users with online audio and video,
      online live streaming, search, and other related services provided for
      this purpose).
    </p>
    <p>
      3. UniLive users/users, including registered and unregistered users.
      Registered user refers to a user who creates a UniLive account to use
      certain features and services of the UniLive platform; Any user who has
      not registered for our products and/or services shall become our non
      registered user from the time they start using our products and/or
      services, and shall comply with all other terms of this agreement except
      for the exclusive agreement of registered users during the use process.
    </p>
    <p>
      4. UniLive account/account refers to the account you register and use on
      the UniLive platform. After registering an account, you can use the
      UniLive platform to achieve one or more functions, including but not
      limited to live/short video shooting, publishing, watching, commenting,
      sharing, searching, etc.
    </p>
    <p>
      5. UniLive platform rules, including all types of service terms, rules,
      specifications, rule interpretations, implementation details, notices,
      announcements, and other regulatory documents that UniLive has already
      published or may publish or revise in the future, as displayed on relevant
      pages. It should be noted that when using certain specific products and/or
      services on the UniLive platform, please pay special attention to the
      relevant terms or rules of service. If you agree to this agreement or
      actually use such services, it shall be deemed that you agree to the
      aforementioned terms.
    </p>
    <h3>2、 Scope of Application and Revision of the Agreement</h3>
    <p>
      1. The scope of application of the agreement. Please be aware that the
      UniLive platform is a comprehensive service platform, and you can
      determine the operator of the UniLive platform who will perform the
      contract with you based on the agreement signed for the corresponding
      service you use or the information disclosed in the corresponding service.
      Under this agreement, the operator of the UniLive platform may change
      based on the business adjustments of the UniLive platform. After the
      change, the UniLive platform operator will jointly perform this agreement
      with you and provide services to you. The operator of the UniLive platform
      may also add new services due to the provision of new services by the
      UniLive platform. If you use the new services, it shall be deemed that you
      agree to the UniLive platform operator corresponding to the new services
      jointly fulfilling this agreement with you. When you have a dispute over
      the use of a service within the UniLive platform, the disputing party is
      the UniLive platform operator corresponding to the specific service you
      are using.
    </p>
    <p>
      2. Scope of the agreement, This agreement includes the UniLive platform
      rules and is an integral part of this agreement, with the same legal
      effect as this agreement. In addition, if you use products and/or services
      provided by third parties on our platform, in addition to complying with
      the provisions of this agreement, you should also comply with the service
      terms of the third party.
    </p>
    <p>
      3. Revision of the agreement, UniLive may revise this agreement in a
      timely manner based on changes in laws and regulations, national policies,
      technical conditions, product features, etc. UniLive will publish the
      revised agreement. Once the aforementioned content is officially released,
      UniLive will deliver it to users in an appropriate manner (pop-up prompts,
      website announcements, system notifications, etc., subject to actual
      circumstances). If you have any questions about the revised agreement
      content or page prompts, you can consult the official customer service of
      UniLive platform so that we can explain and clarify for you. By clicking
      on the page or other means (such as continuing to use), you confirm that
      you have agreed to the revised agreement.
    </p>
    <h3>3、 UniLive Software License and Usage Specification</h3>
    <p>1. Scope of software license</p>
    <p>
      one point one You may need to download the UniLive software to use this
      service. UniLive grants you only a personal, revocable, non transferable,
      and non exclusive license to the UniLive software and its technology (if
      applicable). You can only download, install, use, and run UniLive software
      for the purpose of using this service.
    </p>
    <p>
      one point two All other rights not expressly authorized by this clause and
      other terms of this agreement are still reserved by UniLive, and you must
      obtain written permission from UniLive separately when exercising these
      reserved rights. If UniLive does not exercise any reserved rights, it does
      not constitute a waiver of the reserved rights.
    </p>
    <p>2. Acquisition of software</p>
    <p>
      2.1 When using UniLive software and related services, you can obtain
      UniLive applications through pre installation, third-party downloads
      authorized by UniLive (such as the App Store), and other means.
    </p>
    <p>
      2.2 If you obtain UniLive software from a third party that is not UniLive
      or authorized by UniLive, UniLive cannot guarantee that the software will
      function properly and will not be responsible for any losses caused to you
      as a result.
    </p>
    <p>3. Software installation and uninstallation</p>
    <p>
      3.1 UniLive may have developed different software versions for different
      terminals, systems, etc. Please choose the appropriate version to download
      and install according to your actual situation.
    </p>
    <p>
      After downloading and installing the program, please follow the steps
      prompted by the program to install it correctly.
    </p>
    <p>
      3.3 If you no longer need to use this software or need to install a new
      version of the software, you can uninstall it yourself. If you are willing
      to help UniLive improve its product services, we would greatly appreciate
      it if you could inform us of the reason for uninstalling.
    </p>
    <p>4. Software updates</p>
    <p>
      4.1 In order to improve user experience and enhance service content,
      UniLive will continuously strive to develop new services and provide you
      with software updates from time to time (which may take the form of
      software replacement, modification, feature enhancement, version upgrade,
      etc.).
    </p>
    <p>
      4.2 In order to ensure the security and consistency of functionality of
      this software and services, UniLive has the right to update the software,
      or change or restrict some of its functional effects without special
      notice to you.
    </p>
    <p>
      4.3 After the release of a new version of this software, all or part of
      the functions of the old version may not be available. UniLive does not
      guarantee that older versions of software will continue to be available
      and provide corresponding customer service. Please download the latest
      version in a timely manner.
    </p>
    <h3>4、 Registration, use, and cancellation of accounts</h3>
    <p>
      1. Registration You can create a UniLive account to use certain features
      and services of our platform. You can directly register/log in to your
      UniLive account on our platform.
    </p>
    <p>2. Use</p>
    <p>
      2.1 Users are responsible for ensuring the security of their UniLive
      account information and password, and are legally liable for any actions
      taken under their UniLive account and password. The user agrees not to
      disclose their account and password information to others under any
      circumstances. Please be aware that UniLive will not proactively request
      your account and password. When you suspect that your account has been
      hacked or other unsafe situations, please notify UniLive immediately.
      Regarding account security, UniLive will strive to protect the security of
      your account on the server side at the current level of technology and
      continuously update technical measures. You can seek compensation from the
      infringer through judicial, administrative, and other remedies for any
      losses and consequences caused by your voluntary disclosure or by others'
      attacks, fraud, or other actions. If you need assistance from UniLive,
      UniLive will do its best to assist within the legal framework at that
      time.
    </p>
    <p>
      2.2 The ownership of the UniLive account belongs to Beijing UniLive
      Technology Co., Ltd. After the user completes the application registration
      procedures, the registered UniLive account is only for your personal use.
      At the same time, as your account behavior (including but not limited to
      signing various agreements online, posting information, purchasing goods
      and services, and disclosing information) represents your own behavior,
      you should properly keep your account information and password and be
      responsible for the results of your account behavior. Without UniLive's
      written consent, you may not lend, rent, gift, transfer, sell or license
      others to use your UniLive account in any form. If your account is
      subjected to any operation or use that does not belong to you, please
      notify UniLive immediately to avoid further losses and consequences; If
      UniLive determines that the use of your UniLive account may endanger the
      security of your account and/or the security of UniLive software
      information, based on the consideration of maintaining your property and
      information security, you agree that UniLive may suspend the provision of
      corresponding services until you request restoration through a trusted
      means and the threat of the account to the security of UniLive software
      information has been eliminated.
    </p>
    <p>
      2.3 You understand and promise that the UniLive account information you
      have set up shall not violate national laws, regulations, policies, and
      relevant rules of the services provided by UniLive. The account name,
      UniLive number, avatar, personal introduction, and personal profile
      information you set up must not contain any illegal or harmful
      information. Without the permission of others, you are not allowed to
      register a UniLive account or set UniLive account information in the name
      of others (including but not limited to impersonating others' names,
      trademarks, fonts, portraits, avatars, and other unique identifying
      elements or confusing methods). You are not allowed to maliciously
      register UniLive accounts through frequent registration, batch
      registration, and other behaviors.
    </p>
    <p>
      2.4 According to relevant national laws and regulations and regulatory
      policy requirements, in order to facilitate public supervision for the
      public interest and create good community order, UniLive will display
      information such as the location of Internet Protocol (IP) address of your
      account within a reasonable range on your account personal information
      page and other locations. The specific display location, form, information
      type and other display related arrangements are subject to relevant laws
      and regulations and the actual display situation, which you fully
      understand and agree.
    </p>
    <p>
      2.5 In order to protect account security and prevent account theft, we may
      occasionally use one or more methods to verify the user identity of
      registered users (such as email verification, etc.). If the user fails to
      pass the verification, we have reasonable grounds to suspect that the
      account has been hacked or other unsafe situations, and may unilaterally
      decide whether to suspend or terminate the provision of our products
      and/or services to the account and/or take further measures depending on
      the severity of the situation. You agree that we have taken the
      aforementioned measures to protect the security of user accounts, and we
      do not need to bear any unreasonable responsibility for this.
    </p>
    <p>
      2.6 If a user registers a UniLive account and does not log in for two
      consecutive months, UniLive has the right to freeze, recycle, replace, or
      cancel the account. At the same time, UniLive has the right to take
      measures such as deleting or clearing any records of the account in the
      UniLive software database (including but not limited to registration
      information, virtual assets, etc.) to avoid wasting resources. Any losses
      resulting from this shall be borne by the user.
    </p>
    <p>
      2.7 If your account is frozen, you can reactivate it through other
      verification methods provided by the UniLive platform.
    </p>
    <p>
      3. Cancellation We provide you with the UniLive account cancellation
      function. You can cancel your account through the online cancellation
      method provided by the UniLive platform, contact our customer service, or
      through other methods we provide.
    </p>
    <h3>5、 Protection of User Personal Information</h3>

    <p>
      1. We are well aware of the importance of personal information to you, so
      we attach great importance to protecting your privacy and personal
      information, and treat and process your personal information with a high
      degree of prudence. During your use of our products and/or services, we
      will adopt relevant technical measures and other security measures to
      protect your personal information.
    </p>
    <p>
      2. In the process of registering an account or using related services, we
      need your cooperation to provide some necessary information, such as your
      email. If you do not agree to authorize the necessary personal information
      for the related services, you will not be able to use the services or will
      be restricted during the use process.
    </p>
    <p>
      3. We attach great importance to your management of personal information
      and make every effort to protect your rights to access, copy, correct,
      supplement, delete, revoke consent authorization, cancel accounts, file
      complaints, and report personal information, so that you have the ability
      to understand and protect the security of your personal information.
    </p>
    <p>
      We attach great importance to the protection of personal information of
      minors. If you are a minor under the age of 18, you should obtain the
      consent of your parents or other guardians before using UniLive's
      services.
    </p>
    <p>
      5. Regarding how UniLive collects, uses, stores, and protects your
      personal information, as well as your rights, please visit the UniLive
      Privacy Policy for further information.
    </p>
    <h3>6、 Terms of Use for Minors</h3>
    <p>
      If you are a minor under the age of 18, you should read this agreement and
      use UniLive software and related services under the supervision, guidance,
      and consent of your guardian.
    </p>
    <p>
      2. UniLive attaches great importance to the protection of personal
      information of minors. When filling out personal information, underage
      users should strengthen their awareness of personal protection and treat
      it with caution. Please use UniLive software and related services
      correctly under the guidance of a guardian.
    </p>
    <p>
      3. Minors using this software and related services should learn to use the
      internet correctly within a reasonable range under the supervision and
      guidance of their guardians, avoid becoming addicted to virtual
      cyberspace, develop good internet habits, be good at online learning, and
      not browse harmful information; To communicate honestly and friendly,
      without insulting or deceiving others; To enhance self-protection
      awareness and avoid dating netizens casually; To maintain network security
      and not disrupt network order; To be beneficial to physical and mental
      health, and not to indulge in virtual time and space.
    </p>
    <p>
      4. Your guardian may use recharge, tipping, and other functions when using
      the services provided by UniLive. As a guardian, please keep your payment
      account safe to prevent the guardian from using your payment account for
      recharging and tipping without your consent. UniLive is willing to work
      together with you to avoid such behavior.
    </p>
    <p>
      5. In order to better protect the privacy rights of minors, UniLive
      reminds you to carefully post content containing images of minors. Once
      published, it is deemed that you have obtained the consent of the rights
      holder to display the portrait, voice, and other information of minors,
      and allow UniLive to use and manage content related to minors in
      accordance with this agreement. If the rights holder notifies UniLive that
      the content you post violates the rights of minors, or based on other
      considerations to protect the rights of minors, UniLive has the right to
      process the content you post and notify you.
    </p>
    <h3>7、 User behavior norms</h3>
    <p>
      1. User behavior requirements: You shall be responsible for your use of
      UniLive software and related services. Unless permitted by law or with
      prior written permission from UniLive, you shall not engage in the
      following behaviors when using UniLive software and related services:
    </p>
    <p>
      1.1 Use any plugins, plug-ins, systems, or third-party tools that are not
      authorized or licensed by UniLive to interfere with, disrupt, modify, or
      otherwise affect the normal operation of UniLive software and related
      services.
    </p>
    <p>
      1.2 Using or targeting UniLive software and related services to engage in
      any behavior that endangers computer network security, including but not
      limited to:
    </p>
    <p>
      1.2.1 Activities that endanger network security, such as illegal intrusion
      into others' networks, interference with their normal network functions,
      and theft of network data; 1.2.2 Provide programs and tools specifically
      designed for activities that pose a threat to network security, such as
      intrusion into networks, interference with normal network functions and
      protective measures, and theft of network data; 1.2.3 knowingly providing
      technical support, advertising promotion, payment settlement, and other
      assistance to others engaged in activities that endanger network security;
      1.2.4 Using unauthorized data or accessing unauthorized servers/accounts;
      1.2.5 Unauthorized access to public computer networks or other people's
      computer systems and deletion, modification, or addition of stored
      information; 1.2.6 Unauthorized attempts to explore, scan, test
      vulnerabilities in the UniLive system or network, or engage in other
      activities that undermine network security; 1.2.7 Attempting to interfere
      with or disrupt the normal operation of the UniLive system or website,
      intentionally spreading malicious programs or viruses, and other acts that
      disrupt or interfere with normal network information services; 1.2.8
      Falsifying TCP/IP packet names or partial names.
    </p>
    <p>
      1.3 Reverse engineer, disassemble, compile, or otherwise attempt to
      discover the source code of UniLive software.
    </p>
    <p>
      1.4 Obtain, copy, modify, add, delete, hook up, or create any derivative
      works of UniLive software or data released into the memory of any terminal
      during the operation of UniLive software, interaction data between the
      client and server during the operation of the software, and system data
      necessary for the operation of UniLive software, including but not limited
      to the use of plugins, plug-ins, group controls, or third-party
      tools/services not authorized by UniLive to access UniLive software and
      related systems.
    </p>
    <p>
      1.5 By modifying or forging instructions and data during the operation of
      UniLive software, adding, deleting, or altering the functions or effects
      of UniLive software, or operating or disseminating software or methods
      used for the aforementioned purposes to the public, regardless of whether
      these actions are for commercial purposes.
    </p>
    <p>
      1.6 Delete intellectual property information on UniLive software (and its
      copies) and related content (such as short videos), or modify, delete, or
      avoid any technical measures set up to protect intellectual property.
    </p>
    <p>
      1.7 Use, rent, lend, copy, modify, link, repost, compile, publish,
      vertically search, establish mirror sites, etc. for content owned by
      UniLive or published by other users on the UniLive platform.
    </p>
    <p>
      1.8 Use UniLive account to participate in any illegal or potentially
      illegal (as determined by UniLive) activities or transactions, including
      teaching criminal methods, selling any illegal drugs, money laundering
      activities, fraud, etc.
    </p>
    <p>
      1.9 Other behaviors that violate laws and regulations, this agreement,
      UniLive platform rules, and infringe upon the legitimate rights and
      interests of others. If UniLive has reasonable grounds to believe that any
      of your actions violate or may violate the above agreement, UniLive may
      independently make judgments and take necessary measures to handle it. In
      emergency situations, UniLive may terminate the provision of services to
      you without prior notice and pursue relevant responsibilities in
      accordance with the agreement.
    </p>
    <p>2. Standardization of Information Content</p>
    <p>
      2.1 The information content referred to in this article refers to any
      content created, copied, published, or disseminated by users during the
      use of this software and services, including but not limited to the
      profile picture, nickname, personal introduction, and other personal
      homepage information of UniLive accounts, or the text, images, audio and
      video, live broadcasts, and other content generated by the use of UniLive
      accounts or this software and services.
    </p>
    <p>
      2.2 You understand and agree that UniLive has always been committed to
      providing users with a civilized, healthy, standardized and orderly
      network environment. You may not use your UniLive account or this software
      and services to create, copy, publish, or disseminate content that
      interferes with the normal operation of UniLive, or infringes on the
      legitimate rights and interests of other users or third parties.
    </p>
    <p>2.3 UniLive Basic Rules for Video Uploads</p>
		<p>
			Content Guidelines (Community Standards)
		</p>
		<p>
			Prohibited Violent Content: Videos that depict violence, harm, abuse, or terrorism-related content, including real or simulated violent acts, are not allowed.  
		</p>
		<p>
			Prohibited Pornographic or Explicit Content: Nudity, sexual implications, pornography, or any form of adult content is strictly forbidden.  
		</p>

		<p>
			Prohibited Hate Speech: Content that discriminates against, attacks, or incites hatred toward groups based on race, religion, gender, sexual orientation, nationality, etc., is not allowed.  
		</p>
		<p>
			Prohibited Bullying and Harassment: Uploading content that humiliates, threatens, or harasses others, including inappropriate behavior toward children, is prohibited.  
		</p>
		<p>
			Prohibited Dangerous Acts: Videos that showcase dangerous challenges, pranks, or illegal activities (e.g., drug use, weapon handling) that may lead to harm are not allowed.  
		</p>
    <p>3. Activity participation standards</p>
    <p>
      UniLive or third parties on the UniLive platform may conduct various
      activities from time to time. Before participating in the activities, you
      should carefully read and fully understand the activity rules on the
      corresponding activity page. Your participation in the activities is
      deemed to have fully read and understood the activity rules, and you
      voluntarily agree to be bound by the activity rules. During the process of
      reading the activity rules, if you do not understand or agree with any of
      the content, please stop participating in the relevant activities. Please
      be informed that for users who participate in activities through improper
      means (referring to activity participants who violate the prohibited
      provisions of this agreement or activity rules), the risk control system
      will automatically identify and remove them. You agree that UniLive has
      the right to independently judge whether relevant behaviors are illegal
      based on its own platform's big data analysis and technical recognition
      capabilities, and to cancel the participation qualifications, award
      qualifications, and prize qualifications of users who participate in
      activities through improper means. It will not recognize the results of
      profiting from improper means, and has the right to recover the rewards
      already issued.
    </p>
    <p>4. Take responsibility for your own actions</p>
    <p>
      You understand and agree that you are responsible for your actions under
      your registered account, including any content, comments, likes,
      recommendations, searches, and any consequences arising therefrom. You
      should make your own judgment on the content of this service and bear all
      risks arising from the use of the content, including risks arising from
      reliance on the legality, correctness, completeness, or usefulness of the
      content. UniLive cannot and will not be liable for any loss or damage
      resulting from the aforementioned risks.
    </p>
    <p>
      UniLive respects and protects your and others' legitimate rights and
      interests such as intellectual property, reputation, name, privacy, etc.
      You guarantee that the text, images, audio and video, live broadcasts,
      links, etc. uploaded when using UniLive software and related services do
      not infringe upon any third-party intellectual property rights, reputation
      rights, name rights, privacy rights, and other legitimate rights and
      interests. Otherwise, UniLive has the right to remove the suspected
      infringing content upon notification from the rights holder or relevant
      parties. You shall handle and bear all legal liabilities that may arise
      from all claims made by third parties on your own; If UniLive or its
      affiliates suffer losses (including but not limited to economic,
      reputational, etc.) due to your infringement behavior, you should also
      fully compensate UniLive or its affiliates for all losses suffered.
    </p>
    <h3>8、 Data Usage Standards</h3>
    <p>
      1. Without the written permission of UniLive, users are not allowed to
      engage in any of the following behaviors on their own or authorize, allow,
      or assist any third party with the information content in UniLive software
      and related services:
    </p>
    <p>
      1.1 Copy, read, and use the information content of UniLive software and
      related services for commercial purposes including but not limited to
      promotion, increasing reading and browsing volume;
    </p>
    <p>
      1.2 Unauthorized editing, organizing, and arranging of information content
      related to UniLive software and related services, and displaying it
      through channels other than the source pages of UniLive software and
      related services;
    </p>
    <p>
      1.3 Using any form of identification method, including but not limited to
      special identification, special codes, etc., to guide, transfer, hijack or
      engage in inappropriate behavior of traffic, browsing volume, or content
      of UniLive software and related services on their own or with the
      assistance of third parties;
    </p>
    <p>
      1.4 Other illegal acquisition of information content related to UniLive
      software and related services.
    </p>
    <p>
      2. With written permission from UniLive, users' sharing, forwarding, and
      other behaviors regarding information and content of UniLive software and
      related services shall also comply with the following standards:
    </p>
    <p>
      2.1 Without the prior written consent of UniLive, the relevant search
      keywords, hit rates, classifications, search volume, click through rates,
      browsing volume, and other related data obtained through crawling,
      statistics, and acquisition shall not be publicly disclosed, provided, or
      leaked to any third party in any way;
    </p>
    <p>
      2.2 No changes shall be made to the source web pages of UniLive software
      and related services in any form, including but not limited to the
      homepage (profile) links, advertising system links, and other entrances of
      UniLive software and related services, nor shall any form of obstruction,
      insertion, pop ups, or other obstacles be made to the display of the
      source web pages of UniLive software and related services;
    </p>
    <p>
      2.3 Security, effective, and rigorous measures should be taken to prevent
      the information content of UniLive software and related services from
      being illegally obtained by third parties through any form, including but
      not limited to "spider" programs;
    </p>
    <p>
      2.4 The relevant data content shall not be used for any purpose beyond the
      scope of UniLive's written permission, for any form of sales and
      commercial use, or for any disclosure, provision, or permission to third
      parties for any form of use;
    </p>
    <p>
      2.5 Users who share, forward, or copy UniLive software and related service
      information to any third party shall also comply with other norms and
      standards established by UniLive for this purpose.
    </p>
    <h3>9、 Obligation to pay taxes in accordance with the law</h3>
    <p>
      The income generated or rewards obtained by you on the UniLive platform
      (hereinafter referred to as "relevant income") can be withdrawn to your
      own payment account. According to relevant national laws, administrative
      regulations, and tax regulatory documents, the income generated by you on
      the UniLive platform needs to be subject to relevant taxes and fees or tax
      declarations in accordance with the law. You understand and agree that
      UniLive platform, in accordance with the requirements of the tax
      authorities, needs to collect or use your personal information and tax
      related materials (including but not limited to real name, ID information,
      and contact information) when withholding and paying taxes to the tax
      authorities or handling tax declarations on your behalf. You should
      cooperate with UniLive platform to actively fulfill your tax obligations.
    </p>
    <h3>10、 Intellectual Property</h3>
    <p>
      The intellectual property rights of the content provided by UniLive in
      this software and related services (including but not limited to software,
      technology, programs, web pages, text, images, graphics, audio, video,
      charts, layout design, electronic documents, etc.) belong to UniLive. The
      copyright, trademark, patent, trade secret, and other intellectual
      property rights of the software relied upon by UniLive for providing this
      service belong to UniLive. Without the permission of UniLive, no one is
      allowed to use or transfer (including but not limited to monitoring,
      copying, spreading, displaying, mirroring, uploading, downloading the
      content of this software and related services through any robot, spider or
      other program or device).
    </p>
    <p>
      2. You guarantee that the text, images, videos, audio, and other content
      uploaded and published using this software and related services are
      original or legally authorized (including sub authorization) by you. The
      intellectual property rights of the content uploaded and published through
      UniLive software belong to you or the original copyright owner.
    </p>
    <p>
      3. In order to better share and promote your work, and expand the
      influence of you and your work, unless you have reached a written
      agreement with UniLive to the contrary, you acknowledge, understand, and
      agree to grant UniLive and its affiliates the copyright and related
      competitive rights to the content you publish/upload when using UniLive
      software and related services (including but not limited to text, images,
      videos, audio, and other achievements, as well as the portrait rights,
      performer rights, music, sound, dialogue and other content and elements
      included in the aforementioned achievements), free, non exclusive, and
      multi-level sublicense rights worldwide (including but not limited to
      reproduction rights, adaptation rights, translation rights, assembly
      rights, etc.). Performance rights, information network dissemination
      rights, broadcasting rights, production of derivative products, etc, The
      scope of authorized use mentioned above includes but is not limited to the
      UniLive platform or other third-party platforms, applications, products or
      terminal devices, web pages, pages, and other existing and future carriers
      that cooperate with UniLive. You agree that UniLive may use or develop the
      aforementioned content (in whole or in part) for the purpose of promoting
      or upgrading products/features, researching new products/features, or
      allowing third parties to do so, and undertake not to assert any personal
      rights or compensation for UniLive's use or development of the
      aforementioned content. Based on the characteristics of some service
      functions, the content you upload through UniLive software can be used by
      other UniLive users to create and publish content using UniLive software.
      Works created by users according to the above agreement can only be
      disseminated on the UniLive platform or third-party platforms cooperated
      with UniLive, and may not be used for any commercial purposes without the
      consent of UniLive. If you do not wish UniLive to continue using all or
      part of the authorized content, you can notify UniLive to revoke the
      authorization through UniLive's public announcement.
    </p>
    <p>
      4. In order to increase the exposure and publishing efficiency of your
      work, you agree to authorize UniLive to automatically synchronize and
      publish all content you publish while using UniLive software and related
      services to other service carriers operated by Beijing UniLive Technology
      Co., Ltd. or its affiliated companies, such as clients, websites, web
      pages, and mini programs. Your account's profile picture, nickname, and
      other public non confidential information may also be synchronized. If you
      do not wish to continue authorizing UniLive to automatically synchronize
      the published content, you can revoke the authorization through the
      prompts on the relevant operation pages of the UniLive software or notify
      UniLive to revoke the authorization through other public means.
    </p>
    <p>
      5. You confirm and agree to authorize UniLive to use its own name or
      entrust a third party to protect the intellectual property rights or
      legally authorized content uploaded and published by you on behalf of
      UniLive. At that time, you need to cooperate with UniLive's request and
      provide corresponding written proof documents. The forms of rights
      protection include but are not limited to: monitoring infringement,
      sending rights protection letters, filing lawsuits or arbitrations,
      mediation, settlements, etc. UniLive has the right to make decisions on
      rights protection matters and independently implement them.
    </p>
    <p>
      6. If you find any content that infringes on your rights in this software
      and related websites, please immediately send it to your email address【
      unilive.team@gmail.com 】Notify UniLive and provide you with preliminary
      evidence of your rights. UniLive will promptly handle your complaint in
      accordance with legal regulations.
    </p>
    <p>
      7. UniLive provides technical support for the development and operation of
      this software and related services, and has full rights to all data and
      information generated during the development and operation of this
      software and related services.
    </p>
    <p>
      8. Please do not use any trademarks, service marks, trade names, domain
      names, website names, or other prominent brand features of UniLive,
      including "UniLive Technology", "UniLive", "unilive. com", etc., without
      authorization under any circumstances (hereinafter collectively referred
      to as "logos"). Without the prior written consent of UniLive, you shall
      not display, use, or engage in any other handling of the aforementioned
      logos in any form, whether alone or in combination, as stated in these
      terms. If you violate this agreement by using the above-mentioned
      trademarks, logos, etc. of UniLive, causing losses to UniLive or others,
      you shall bear all legal responsibilities.
    </p>
    <h3>11、 Promotion</h3>
    <p>
      1. You understand and agree that in the process of providing UniLive
      software and related services, we reserve the right to place and/or mount
      commercial advertisements (including advertising links) or any other type
      of commercial and non-commercial information (collectively referred to as
      "promotional information") in various ways by ourselves or our partners.
      This right includes but is not limited to UniLive's ability to place
      and/or mount promotional information on any location on the UniLive
      platform, including the content you upload, disseminate, and your account.
      Subject to compliance with laws and regulations, the form, duration,
      location, and content of such promotional information placement and/or
      mounting shall not be subject to any third-party intervention.
    </p>
    <p>2. Regarding commercial advertising</p>
    <p>
      2.1 If you are unwilling to receive relevant advertisements, you have the
      right to choose "not interested" in the advertising information, and the
      push of similar advertisements will be reduced.
    </p>
    <p>
      2.2 We will fulfill advertising related obligations in accordance with
      legal provisions, but you understand and agree that advertising partners
      and advertising content are not under our control, and you should
      carefully judge the authenticity and reliability of advertising content.
      You are responsible for any form of behavior or disputes arising from our
      products and/or services with advertising partners, and we will resolve
      them on your own. Except for the responsibilities that are required by
      laws and regulations to be borne by us, we will not assume any additional
      responsibilities, but we will provide necessary assistance as needed in
      accordance with the law.
    </p>
    <p>
      2.3 If you do not wish to receive UniLive push notification services, you
      have the right to turn off the service on your own in the mobile system
      notification management.
    </p>
    <h3>12、 Special agreements regarding individual services</h3>
    <p>
      1. The UniLive software and related services include information or
      content links obtained by UniLive in various legal ways, as well as other
      individual services operated legally by UniLive and its affiliated
      companies. UniLive may from time to time add, reduce, or modify the
      settings and services of these special sections. You can enable and use
      the above-mentioned individual service functions in the UniLive software.
      Some individual services may require you to simultaneously accept
      agreements or other rules specifically formulated for that service that
      bind you and the service provider. UniLive will prominently provide these
      agreements and rules for your reference. Once you agree to the agreement
      or start using the above services, it is deemed that you also accept the
      provisions of the relevant agreements and rules regarding the individual
      services.
    </p>
    <p>
      2. When using software and/or related services provided by third parties
      in UniLive software, in addition to complying with this agreement and
      UniLive platform rules, you should also comply with the agreements and
      relevant rules of the third party. If any disputes, losses or damages
      arise from third-party software and/or related services, you shall resolve
      them with the third party yourself, and UniLive shall not be liable to you
      or any third party for this.
    </p>
    <h3>13、 Change, interruption, and termination of services</h3>
    <p>
      1. Within the scope permitted by laws and regulations, we may temporarily
      or permanently change or terminate our products and/or services (or any
      part thereof) based on our business development. After being notified in
      writing through internal messages, update/termination notices, etc. within
      a reasonable period of time, such changes or termination shall not be
      liable for breach of contract by you or any third party
    </p>
    <p>
      2. In addition to the above circumstances, we have the right to suspend or
      terminate the provision of products and/or services to you in the
      following situations:
    </p>
    <p>2.1 If you voluntarily make a request;</p>
    <p>
      2.2 If you have engaged in any behavior that violates national laws,
      regulations, or regulatory policies, violates this agreement, or damages
      our reputation or rights, or if we have independently judged that you have
      engaged in such behavior;
    </p>
    <p>
      2.3 According to laws, regulations, regulatory policies, or requirements
      of authorized authorities;
    </p>
    <p>
      2.4 For the purpose of maintaining account and system security in
      emergency situations;
    </p>
    <p>
      2.5 Force majeure (force majeure refers to unforeseeable, unavoidable and
      insurmountable objective conditions. In view of the special nature of the
      Internet, the term "force majeure" in this agreement also includes hacker
      attacks, significant impacts caused by technical adjustment of the
      telecommunications sector, temporary closures caused by government
      regulation, virus attacks and other conditions affecting the normal
      operation of the Internet);
    </p>
    <p>2.6 Other irresistible situations.</p>
    <p>
      3. In the event of termination as described above, both you and we agree
      to the following handling method:
    </p>
    <p>
      3.1 If you have ongoing transactions on our platform, we will handle them
      reasonably according to the situation;
    </p>
    <p>
      3.2 If termination is caused by your violation of this agreement, we have
      the right to demand that you bear corresponding breach of contract
      liability as appropriate;
    </p>
    <p>
      3.3 Unless otherwise provided by laws and regulations or stated by us, we
      shall not be liable to you or any third party.
    </p>
    <h3>14、 Disclaimer</h3>
    <p>
      1. You understand and agree that UniLive software and related services may
      be affected or interfered with by various factors. Unless otherwise
      specified by laws and regulations, UniLive does not guarantee (including
      but not limited to):
    </p>
    <p>
      1.1 UniLive software and related services are fully suitable for the
      user's usage needs;
    </p>
    <p>
      1.2 UniLive is undisturbed, completely timely, safe, reliable, or error
      free; Any software, services, or other materials obtained by the user
      through UniLive that meet the user's expectations;
    </p>
    <p>Any errors in UniLive software will be corrected.</p>
    <p>
      2. For network information, account passwords, advertisements or
      promotions related to loans or other property, please handle them with
      caution and make your own judgments. Otherwise, UniLive shall not be
      liable for any direct, indirect, incidental, special, derivative or
      punitive legal responsibility for any profit, business reputation, data
      loss or other tangible or intangible losses suffered by you as a result.
    </p>
    <p>
      3. You understand and agree that in the process of using UniLive software
      and related services, you may encounter force majeure and other factors.
      In the event of force majeure, UniLive will strive to repair it in a
      timely manner. However, if the user suffers losses due to force majeure,
      the user agrees that UniLive will not be held responsible.
    </p>
    <p>
      4. UniLive has the right to handle illegal and irregular content in
      accordance with the provisions of this agreement. This right does not
      constitute an obligation or commitment of UniLive, and UniLive cannot
      guarantee timely detection or corresponding handling of illegal
      activities.
    </p>
    <p>
      5. You understand and agree that UniLive does not provide any express or
      implied warranties or conditions regarding this service, including but not
      limited to commercial suitability, specific use applicability, etc.
    </p>
    <p>
      6. You understand and agree that this agreement is drafted and entered
      into by both parties based on the principles of complying with national
      laws and regulations, maintaining public order and good customs,
      protecting the legitimate rights and interests of others, and improving
      the user experience of UniLive. For matters related to the review,
      supervision, or other platform obligations that UniLive must fulfill
      during the performance of the agreement, UniLive will make every effort
      within its capabilities to execute and judge the relevant work in
      accordance with relevant laws and regulations, but does not guarantee that
      UniLive's judgment is completely consistent with the judgment of judicial
      and administrative authorities. If any consequences arise as a result, the
      user has understood and agreed to bear them on their own.
    </p>
    <h3>15、 Default handling</h3>
    <p>
      1. In response to your violation of this agreement or other UniLive
      platform rules, UniLive has the right to independently judge and take
      measures such as warning reminders, deadline corrections, restriction of
      some or all functions of the account, suspension of use until termination
      of service provision, account closure, prohibition of re registration,
      etc. As a result, you are unable to use the account and related services
      normally, and you are unable to obtain assets or other rights and
      interests in your account (including but not limited to the loss of user
      information, virtual assets and related data, products or services due to
      clearing or inability to use), which shall be borne by you. UniLive shall
      not be held legally responsible. UniLive has the right to announce
      violations and their handling results, and has the right to decide whether
      to restore the use of relevant accounts based on the actual situation.
      UniLive will keep relevant records of suspected violations of laws and
      regulations or illegal activities, and has the right to report to relevant
      authorities, cooperate with relevant authorities in investigations, report
      to public security organs, etc. UniLive has the right not to restore
      deleted content.
    </p>
    <p>
      2. If you violate this agreement or other UniLive platform rules and
      regulations, resulting in third-party complaints or litigation claims, you
      shall handle it yourself and bear all legal responsibilities that may
      arise as a result. If UniLive or its affiliates compensate any third party
      or suffer penalties from national authorities due to your illegal or
      breach of contract behavior, you should also fully compensate UniLive or
      its affiliates for all losses suffered as a result.
    </p>
    <h3>16、 Other</h3>
    <p>
      1. The titles of all clauses in this agreement are for the convenience of
      reading only and have no actual meaning in themselves, and cannot be used
      as a basis for interpreting the meaning of this agreement.
    </p>
    <p>
      2. Regardless of the reason why some of the terms of this agreement are
      invalid or unenforceable, the remaining terms shall remain valid and
      binding on both parties.
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          value: "中文",
          label: "中文",
        },
        {
          value: "English",
          label: "English",
        },
        {
          value: "한국인",
          label: "한국인",
        },
        {
          value: "日本語",
          label: "日本語",
        }, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
      ],
      value: "",
      language: "",
    };
  },
  created() {
    // url获取参数
    this.language = this.$route.query.language;
    if (this.language == "zh_CN") {
      this.$router.push({
        path: "/UserServiceAgreementZh",
      });
    }
    if (this.language == "en_US") {
      this.$router.push({
        path: "/UserServiceAgreementEn",
      });
    }
    if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/UserServiceAgreementKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/UserServiceAgreementJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/UserServiceAgreementRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/UserServiceAgreementVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/UserServiceAgreementTh'
			})
		}
  },
  methods: {
    clickChange(value) {
      this.value = value;
      if (value == "中文") {
        this.$router.push({
          path: "/UserServiceAgreementZh",
        });
      }
      if (value == "English") {
        this.$router.push({
          path: "/UserServiceAgreementEn",
        });
      }
      if (value == "한국인") {
        this.$router.push({
          path: "/UserServiceAgreementKo",
        });
      }
      if (value == "日本語") {
        this.$router.push({
          path: "/UserServiceAgreementJa",
        });
      }
      if (value == 'Русский') {
				this.$router.push({
					path: '/UserServiceAgreementRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/UserServiceAgreementVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/UserServiceAgreementTh'
				})
			}
    },
  },
};
</script>

<style>
.container {
  padding: 0.1rem 0.3rem;
}

.lang {
  text-align: right;
  margin-bottom: 0.3rem;
}

h1,
h2 {
  color: #333;
  font-size: 0.5rem;
}

h3 {
  margin-top: 20px;
  font-size: 0.4rem;
}

p {
  margin-bottom: 15px;
  font-size: 0.3rem;
}

li {
  font-size: 0.3rem;
}
</style>
